<!-- 收款方式管理 -->
<template>
  <div class="viewsPages">
    <div class="UserInfo-title">{{ $t("userPage.text15") }}</div>
    <div class="views-BankAdd">
      <!-- <div class="views-title">收款方式管理</div> -->
      <!-- <div class="views-title views-title-small border">
                <div class="title-btn" @click="_jump('bankCard')">{{$t("userPage.text14")}}</div>
                <i class="views-title-icon"></i>
                <div>{{$t("userPage.text15")}}</div>
            </div> -->

      <!-- <Tab @tab="tab" :tabIndex="1" :arr="['银行卡', '数字货币', 'origo钱包']" /> -->
      <!-- 添加界面 -->
      <div class="BankAdd-list">
        <Descriptions :column="1" v-if="activeIndex == 0">
          <!-- 账户姓名 -->
          <DescriptionsItem :label="$t('userPage.text16')">
            <Input v-model="userData.realName" disabled />
          </DescriptionsItem>
          <!-- 银行名称 -->
          <DescriptionsItem :label="$t('userPage.text17')">
            <Select
              v-model="form.name"
              class="BankAdd-select"
              :placeholder="$t('userPage.text18')"
            >
              <template slot="prefix" v-if="currBank.imgUrl">
                <img
                  loading="lazy"
                  class="prefixSlot"
                  v-lazy="systemImgHost + currBank.imgUrl"
                />
              </template>
              <Option
                v-for="item in cardBank"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
                <img loading="lazy" v-lazy="systemImgHost + item.imgUrl" />
                <span>{{ item.name }}</span>
              </Option>
            </Select>
          </DescriptionsItem>
          <!-- 支行名称 -->
          <DescriptionsItem :label="$t('userPage.text19')">
            <Input
              v-model.trim="form.branch"
              clearable
              :placeholder="$t('userPage.text20')"
            />
          </DescriptionsItem>
          <!-- 银行卡号 -->
          <DescriptionsItem :label="$t('userPage.text21')">
            <Input
              v-model="form.number"
              clearable
              @input="form.number = onChangeNumber(form.number)"
              :placeholder="$t('userPage.text22')"
            />
          </DescriptionsItem>
          <!-- 确认卡号 -->
          <DescriptionsItem :label="$t('userPage.text23')">
            <Input
              v-model="form.number2"
              clearable
              @input="form.number2 = onChangeNumber(form.number2)"
              :placeholder="$t('userPage.text24')"
            />
          </DescriptionsItem>
          <!-- 手机号 -->
          <DescriptionsItem
            :label="$t('userPage.text25')"
            v-if="this.userBankBindSmsAction == 1"
          >
            <Input v-model="userData.phone" disabled />
          </DescriptionsItem>
          <!-- 验证码 -->
          <DescriptionsItem
            :label="$t('userPage.text26')"
            v-if="this.userBankBindSmsAction == 1"
          >
            <Input
              v-model="form.code"
              clearable
              :placeholder="$t('userPage.text27')"
            />
            <Button
              @click="sendSms"
              class="BankAdd-code"
              :disabled="!!smsTimeout"
              >{{
                smsTimeout ? smsTimeout + "s" : $t("userPage.text28")
              }}</Button
            >
          </DescriptionsItem>
        </Descriptions>
        <Descriptions :column="1" v-if="activeIndex == 1">
          <!-- 账户姓名 -->
          <DescriptionsItem :label="$t('userPage.text29')">
            <Input v-model="userData.realName" disabled />
          </DescriptionsItem>
          <!-- 银行名称 -->
          <DescriptionsItem :label="$t('userPage.text30')">
            <Select
              v-model="form.name"
              class="BankAdd-select"
              :placeholder="$t('userPage.text18')"
            >
              <template slot="prefix" v-if="currBank.imgUrl">
                <img
                  loading="lazy"
                  class="prefixSlot"
                  v-lazy="systemImgHost + currBank.imgUrl"
                />
              </template>
              <Option
                v-for="item in usdtBank"
                :style="{ fontWeight: 'bold' }"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
                <img loading="lazy" v-lazy="systemImgHost + item.imgUrl" />
                <span>{{ item.name }}</span>
              </Option>
            </Select>
          </DescriptionsItem>
          <!-- 支行名称 -->
          <DescriptionsItem :label="$t('userPage.text31')">
            <Input
              v-model.trim="form.branch"
              clearable
              :placeholder="$t('userPage.text32')"
            />
          </DescriptionsItem>
          <!-- 银行卡号 -->
          <DescriptionsItem :label="$t('userPage.text33')">
            <Input
              v-model.trim="form.number"
              clearable
              :placeholder="$t('userPage.text34')"
            />
          </DescriptionsItem>
          <!-- 确认卡号 -->
          <DescriptionsItem :label="$t('userPage.text35')">
            <Input
              v-model.trim="form.number2"
              clearable
              :placeholder="$t('userPage.text36')"
            />
          </DescriptionsItem>
          <!-- 手机号 -->
          <DescriptionsItem
            :label="$t('userPage.text37')"
            v-if="this.userBankBindUsdtAction == 1"
          >
            <Input v-model="userData.phone" disabled />
          </DescriptionsItem>
          <!-- 验证码 -->
          <DescriptionsItem
            :label="$t('userPage.text26')"
            v-if="this.userBankBindUsdtAction == 1"
          >
            <Input
              v-model="form.code"
              clearable
              :placeholder="$t('userPage.text27')"
            />
            <Button
              @click="sendSms"
              class="BankAdd-code"
              :disabled="!!smsTimeout"
              >{{
                smsTimeout ? smsTimeout + "s" : $t("userPage.text28")
              }}</Button
            >
          </DescriptionsItem>
        </Descriptions>

        <Descriptions :column="1" v-if="activeIndex == 2">
          <!-- 支行名称 -->
          <DescriptionsItem :label="$t('userPage.text38')">
            <Select v-model="form.name" placeholder='$t("userPage.text39")'>
              <Option :label="'USDT'" :value="'USDT'"></Option>
            </Select>
          </DescriptionsItem>
          <!-- 钱包地址 -->
          <DescriptionsItem :label="$t('userPage.text40')">
            <Input
              v-model.trim="form.number"
              clearable
              :placeholder="$t('userPage.text41')"
            />
          </DescriptionsItem>
          <!-- 确认地址 -->
          <DescriptionsItem :label="$t('userPage.text42')">
            <Input
              v-model.trim="form.number2"
              clearable
              :placeholder="$t('userPage.text43')"
            />
          </DescriptionsItem>
          <!-- 手机号 -->
          <DescriptionsItem
            :label="$t('userPage.text25')"
            v-if="this.userBankBindUsdtAction == 1"
          >
            <Input v-model="userData.phone" disabled />
          </DescriptionsItem>
          <!-- 验证码 -->
          <DescriptionsItem
            :label="$t('userPage.text26')"
            v-if="this.userBankBindUsdtAction == 1"
          >
            <Input
              v-model="form.code"
              clearable
              :placeholder="$t('userPage.text27')"
            />
            <Button
              @click="sendSms"
              class="BankAdd-code"
              :disabled="!!smsTimeout"
              >{{
                smsTimeout ? smsTimeout + "s" : $t("userPage.text28")
              }}</Button
            >
          </DescriptionsItem>
        </Descriptions>
        <div class="BankAddbtn">
          <Button type="primary" class="BankAdd-btn" @click="addCard">{{
            $t("userPage.text44")
          }}</Button>
          <Button @click="_jump('bankCard')" class="gobalck-btn">{{
            $t("userPage.text45")
          }}</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  _getBankCountConfig,
  _getBindSmsSetting,
  _getBanks,
} from "@/core/api/modules/system";
import { _smsCode } from "@/core/api/modules/user";
import { _addCard } from "@/core/comp/pages/userInfo";
import { _jump } from "@/core/utils/utils";
import store from "@/core/store";
import {
  Descriptions,
  DescriptionsItem,
  Button,
  Select,
  Option,
  Input,
} from "element-ui";
export default {
  components: {
    Descriptions,
    DescriptionsItem,
    Button,
    Select,
    Option,
    Input,
  },
  data() {
    return {
      form: {
        name: "", // 银行
        branch: "", // 支行（）
        realName: "", // 真实姓名（）
        number: "", // 银行卡号（）
        number2: "", // 确认卡号（）
        code: "", // 手机验证码
      },
      smsTimeout: 0, // 短信倒计时
      activeIndex: 0,
      tabArr: [
        this.$t("userPage.text46"),
        this.$t("userPage.text47"),
        this.$t("userPage.text48"),
      ],
      userBankBindPhone: false,
    };
  },
  filters: {
    // 银行卡展示
    banktitle(val) {
      if (val) {
        return val.name + "(" + val.number.substr(-4) + ")";
      } else {
        return;
      }
    },
  },
  watch: {
    ["form.name"]() {
      // 银行卡
      if (this.form.name != "数字货币" && this.activeIndex == 0) {
        this.userBankBindPhone = this.userBankBindSmsAction == 1 ? true : false;
      } else {
        //数字货币
        this.userBankBindPhone =
          this.userBankBindUsdtAction == 1 ? true : false;
      }
      store.dispatch("userBankBindPhoneAction", this.userBankBindPhone);
    },
    $router: {
      handler(val) {
        if (val.currentRoute.query.tab) {
          this.activeIndex = val.currentRoute.query.tab;
          if (this.activeIndex == 1) {
            this.form.name = "数字货币";
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    isUSDT() {
      // 是否是数字货币
      return (this.form.name == "数字货币") | "USDT";
    },
    ...mapGetters([
      "userData", // 用户数据
      // 'userBankBindPhone', // 绑定银行卡前是否需要短信验证
      "userBankBindUsdtAction", // 绑定数字货币前是否需要先验证短信
      "userBankBindSmsAction", // 绑定银行卡前是否需要先验证短信
      "userBanks", // 可选银行列表
      "systemImgHost",
    ]),
    cardBank() {
      // 银行卡列表
      return this.userBanks.filter((e) => e.name != "数字货币") || {};
    },
    usdtBank() {
      // 数字货币列表
      return [
        {
          name: "数字货币",
        },
      ];
    },
    currBank() {
      // 当前银行卡
      return this.userBanks.find((e) => e.name === this.form.name) || {};
    },
  },
  created() {
    _getBankCountConfig();
    _getBindSmsSetting();
    setTimeout(() => {
      _getBanks();
    }, 500);
    console.log("userBanks", this.userBanks);
  },
  methods: {
    _jump,
    // 切换导航
    tab(num) {
      if (this.activeIndex == num) return;
      this.activeIndex = num;
      this.resetForm();
    },

    // 发送短信
    sendSms() {
      if (this.smsTimeout) return;
      _smsCode(this.userData.phone, 7).then((res) => {
        if (!res) return;
        this.smsTimeout = 60;
        const interval = setInterval(() => {
          this.smsTimeout--;
          if (this.smsTimeout == 0) clearInterval(interval);
        }, 1000);
      });
    },
    // 添加银行卡
    addCard() {
      if (this.activeIndex == 1 && this.form.name == "USDT") {
        this.form.branch = "USDT";
      }
      const result = _addCard(this.form, this.isUSDT);
      if (result.then)
        result.then((res) => {
          if (res) {
            this.resetForm();
            _jump("bankCard");
          }
        });
    },
    // 重置表单
    resetForm() {
      this.form = {
        name: "",
        branch: "",
        realName: "",
        number: "",
        number2: "",
        code: "",
      };
    },

    //数字
    onChangeNumber(e) {
      return e.replace(/[^\d]/g, "");
    },
  },
};
</script>

<style lang="scss">
$imgUrl: "~@/assets/images/my/";
.views-BankAdd {
  width: 890px;
  margin: 20px auto;
  padding: 10px 40px;
  border-radius: 10px;
  background: #dedede;
  .BankAdd-list {
    margin-top: 40px;
    .el-descriptions {
      .el-descriptions__body {
        background: transparent;
        .el-descriptions-row {
          .el-descriptions-item__content {
            height: 100%;
            width: 470px;
            display: flex;
            align-items: center;
            border-radius: 5px;
            // 验证码
            .BankAdd-code {
              min-width: 100px;
              min-height: 35px;
              line-height: 35px;
              font-size: 14px;
              padding: 0;
              color: #fff;
              text-align: center;
              border-radius: 6px;
              border: none;
              margin-left: 10px;
              background: linear-gradient(180deg, #c4e8fc, #42b4f1, #00a6ff);
            }
            .is-disabled {
              .el-input__inner {
                background: #fff;
              }
            }
            .BankAdd-select {
              // 银行卡图标
              .el-input__prefix {
                display: flex;
                align-items: center;
                img {
                  width: 25px;
                  height: 25px;
                  margin: auto 0;
                }
              }
              .el-input__inner {
                padding-left: 33px !important;
              }
            }
            .el-input__inner {
              height: 50px;
              color: #8e8e8e;
              line-height: 50px;
              width: 470px;
              border-radius: 5px;
              border: 1px solid #e3e3e3;
              background: #ffffff;
              // padding: 0 15px;
              &:focus {
                border: 1px solid #42b4f1;
              }
            }
          }
          .el-descriptions-item__label {
            width: 200px;
            color: #8e8e8e;
            font-size: 16px;
            font-weight: 700;
            display: flex;
            justify-content: flex-start;
            line-height: 50px;
          }
        }
      }
    }
    .BankAddbtn {
      margin: 40px auto;
      text-align: center;
      .BankAdd-btn,
      .gobalck-btn {
        margin-right: 20px;
        color: #fff;
        border: none;
        width: 200px;
        height: 40px;
        text-align: center;
        font-size: 18px;
        box-shadow: none;
        background: #ce06cd;
      }
      .gobalck-btn {
        background: #ce06cd;
      }
    }
  }
}
</style>